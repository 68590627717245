import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import Protect from "layouts/protect";
const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />

      <Route path="a/*" element={
        <Protect>
          <AdminLayout />
        </Protect>
      } />

      <Route path="/" element={<Navigate to="/a" replace />} />
      <Route path="*" element={<Navigate to="/a" replace />} />
    </Routes>
  );
};

export default App;
