import Widget from "components/widget/Widget";
import OrderTable from "./OrderTable";
import { useEffect, useState } from "react";
import Spinner from "components/spinner/spinner";
import apiCall from "utils/apicall";

const Dashboard = ({
  setNavbarName
}: {
  setNavbarName: any
}) => {

  setNavbarName("Orders")

  let [loading, setLoading] = useState(true)
  let [orderOverView, setOrderOverView] = useState({
    all_order_count: 0,
    cancel_request_count: 0,
    checked_count: 0,
    new_count: 0
  })


  const fetchAndLoadData = () => {

    setLoading(true)

    apiCall({
      method: "POST",
      url: `/orders/overview`,
      data: {}
    }).then((res: any) => {
      setOrderOverView(res.data)
      setLoading(false)
    }).catch(() => {
      alert("error... data fetch")
    })

  }

  useEffect(() => {
    fetchAndLoadData()
  }, [])

  return (

    loading ?
      <div className="h-[300px] w-full flex">
        <Spinner />
      </div>
      :
      <div>

        <div className="mt-3 grid gap-5 grid-cols-4">
          <Widget
            title={"New Order"}
            subtitle={orderOverView.new_count.toString()}
          />
          <Widget
            title={"Cancel Request"}
            subtitle={orderOverView.cancel_request_count.toString()}
          />
          <Widget
            title={"Checked Order"}
            subtitle={orderOverView.checked_count.toString()}
          />
          <Widget
            title={"Total Order"}
            subtitle={orderOverView.all_order_count.toString()}
          />
        </div>

        <div className="mt-5 grid ">
          <OrderTable />
        </div>

        {/*      
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <div>
          <CheckTable tableData={tableDataCheck} />
        </div>

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div>

        <ComplexTable tableData={tableDataComplex} />

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div>
      </div> 
      */}


      </div>
  );
};

export default Dashboard;
