import React, { useEffect, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import apiCall from "utils/apicall";
import Spinner from "components/spinner/spinner";
import Button from "components/button/button";
import { useNavigate } from "react-router-dom";


function OrderTable() {

  const navigate = useNavigate();

  const [orderList, setOrderList] = useState([])

  const [orderType, setOrderType] = useState("NEW")
  const [loadOrders, setLoadOrders] = useState(true)

  const parepageorder = 10
  const [pageNo, setPageNo] = useState(1)
  const [orderCount, setOrderCount] = useState(0)

  const statusColor: any = {
    "NEW": "lightgreen",
    "CHECKED": "pink",
    "CANCEL REQUEST": "red",
    "CANCELLED": "orange"
  }

  const loadData = () => {
    setLoadOrders(true)

    apiCall({
      method: "POST",
      url: `/orders/list/${parepageorder}/${pageNo}?type=${orderType}`,
      data: {}
    }).then((res: any) => {
      setOrderList(res?.data.orders);
      setLoadOrders(false)
      setOrderCount(res.data.count)
    }).catch(() => {
      alert("error... data fetch")
    })
  }

  const rows = orderList?.map((x, i) => {
    return (
      <tr key={x?._id} onClick={() => { navigate(`/a/order/${x._id}`)}} className="cursor-pointer transition-all duration-300 hover:bg-gray-100">
        <td className="min-w-[150px] border-white/0 py-3 px-4">{x._id}</td>
        <td className="min-w-[150px] border-white/0 py-3 px-4">{x.address.name}</td>
        <td className="min-w-[150px] border-white/0 py-3 px-4">{(() => {
          var isoDateTime = new Date(x.date);
          var localDateTime = isoDateTime.toLocaleString()
          return (
            <>
              {localDateTime}
            </>
          )
        })()}</td>
        <td className="min-w-[150px] border-white/0 py-3 px-4">₹ {x.subtotal}</td>
        <td className="min-w-[150px] border-white/0 py-3 px-4" style={{
          color: statusColor[x.status] || "red"
        }}>{x.status}</td>
      </tr>
    );
  });

  useEffect(() => {
    loadData()
  }, [pageNo, orderType])

  return (
    <Card extra={"w-full pb-4 p-4 h-full"}>
      <header className="relative flex items-center justify-between">
        <div className="capitalize text-xl font-bold text-navy-700 dark:text-white">
          {orderType.toLowerCase()} Orders
        </div>
        <CardMenu links={[
          {
            name: "NEW",
            onClick: () => {
              setOrderType("NEW")
              setPageNo(1)
            }
          },
          {
            name: "CHECKED",
            onClick: () => {
              setOrderType("CHECKED")
              setPageNo(1)
            }
          },
          {
            name: "CANCELLED",
            onClick: () => {
              setOrderType("CANCELLED")
              setPageNo(1)
            }
          },
          {
            name: "CANCEL REQUEST",
            onClick: () => {
              setOrderType("CANCEL REQUEST")
              setPageNo(1)
            }
          },
          {
            name: "ALL",
            onClick: () => {
              setOrderType("ALL")
              setPageNo(1)
            }
          }
        ]} />
      </header>

      {

        loadOrders ?
          <div className="h-[500px] w-full flex">
            <Spinner />
          </div>
          :
          <div className="mt-0 overflow-x-scroll xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                <tr className="!border-px !border-gray-400">
                  <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Order Id</th>
                  <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Name</th>
                  <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Date</th>
                  <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Subtotal</th>
                  <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Status</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>

            </table>
              {orderCount == 0 &&

                <div>
                  <p className="text-2xl font-bold text-gray-500 text-center w-full !my-[50px]">
                  No Any Orders!
                  </p>
                </div>
              
              }
            <div className="flex mt-3 justify-between">
              <Button name="Previous" disabled={1 == pageNo} onClick={() => { setPageNo(pageNo - 1) }} />
              <Button name="Next" disabled={orderCount == 0 || Math.ceil(orderCount / parepageorder) == pageNo} onClick={() => { setPageNo(pageNo + 1) }} />
            </div>
          </div>
      }
    </Card>
  );
}

export default OrderTable;
