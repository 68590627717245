import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";

import {
  BiCart,
  BiUser
} from "react-icons/bi";

const routes: RoutesType[] = [
  {
    name: "Orders",
    path: "/a",
    icon: <BiCart className="h-6 w-6" />,
  },
  {
    name: "People",
    path: "/a/people",
    icon: <BiUser className="h-6 w-6" />,
  },
];


export const SidebarLinks = (): JSX.Element => {
  let location = useLocation();

  const activeRoute = (routeName: string) => {
    if (routeName == "/a") {
      if (location.pathname.startsWith("/a/order")) {
        return true
      }
    }
    if (routeName == "/a") {
      return location.pathname == "/a"
    }
    return location.pathname.startsWith(routeName);
  };

  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route, index) => {
        return (
          <Link key={index} to={route.path}>
            <div className="relative mb-3 flex hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`${activeRoute(route.path) === true
                    ? "font-bold text-brand-500 dark:text-white"
                    : "font-medium text-gray-600"
                    }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${activeRoute(route.path) === true
                    ? "font-bold text-navy-700 dark:text-white"
                    : "font-medium text-gray-600"
                    }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : null}
            </div>
          </Link>
        );
    });
  };
  // BRAND
  return <>{createLinks(routes)}</>;
};

export default SidebarLinks;
