import React, { useEffect, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import apiCall from "utils/apicall";
import Spinner from "components/spinner/spinner";
import Button from "components/button/button";
import { useNavigate } from "react-router-dom";


function PeopleTable() {

  const navigate = useNavigate();

  const [orderList, setOrderList] = useState([])

  const [loadOrders, setLoadOrders] = useState(true)

  const parepageorder = 10
  const [pageNo, setPageNo] = useState(1)
  const [peopleCount, setPeopleCount] = useState(0)

  const loadData = () => {
    setLoadOrders(true)

    apiCall({
      method: "POST",
      url: `/account/list/${parepageorder}/${pageNo}`,
      data: {}
    }).then((res: any) => {
      setOrderList(res?.data.people);
      setLoadOrders(false)
      setPeopleCount(res.data.count)
    }).catch(() => {
      alert("error... data fetch")
    })
  }

  const rows = orderList?.map((x, i) => {
    return (
      <tr key={x?._id} onClick={() => { /* navigate(`/a/order/${x._id}`) */ }} className="cursor-pointer transition-all duration-300 hover:bg-gray-100">
        <td className="min-w-[150px] border-white/0 py-3 px-4">{i + 1 + ((pageNo - 1) * parepageorder)}</td>
        <td className="min-w-[150px] border-white/0 py-3 px-4">{x?.name}</td>
        <td className="min-w-[150px] border-white/0 py-3 px-4">{x?.phone}</td>
        <td className="min-w-[150px] border-white/0 py-3 px-4">{x?.email}</td>
      </tr>
    );
  });

  useEffect(() => {
    loadData()
  }, [pageNo])

  return (
    <Card extra={"w-full pb-4 p-4 h-full"}>
      <header className="relative flex items-center justify-between h-[40px]">
        <div className="capitalize text-xl font-bold text-navy-700 dark:text-white">
          People List
        </div>

      </header>

      {

        loadOrders ?
          <div className="h-[500px] w-full flex">
            <Spinner />
          </div>
          :
          <div className="mt-0 overflow-x-scroll xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                <tr className="!border-px !border-gray-400">
                  <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Sr no</th>
                  <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Name</th>
                  <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Mobile no</th>
                  <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">E-mail</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>

            </table>
            {peopleCount == 0 &&

              <div>
                <p className="text-2xl font-bold text-gray-500 text-center w-full !my-[50px]">
                  No Any Orders!
                </p>
              </div>

            }
            <div className="flex mt-3 justify-between">
              <Button name="Previous" disabled={1 == pageNo} onClick={() => { setPageNo(pageNo - 1) }} />
              <Button name="Next" disabled={peopleCount == 0 || Math.ceil(peopleCount / parepageorder) == pageNo} onClick={() => { setPageNo(pageNo + 1) }} />
            </div>
          </div>
      }
    </Card>
  );
}

export default PeopleTable;
