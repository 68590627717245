import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import Dashboard from "views/admin/default";
import Order from "views/admin/order/order";
import People from "views/admin/people/people";

export default function Admin(props: { [x: string]: any }) {

  const [open, setOpen] = React.useState(true);
  const [brandText, setBrandText] = React.useState("");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={brandText}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>

                <Route
                  path="/"
                  element={<Dashboard setNavbarName={setBrandText} />}
                />
                <Route
                  path="/order/:id"
                  element={<Order setNavbarName={setBrandText} />}
                />
                <Route
                  path="/people"
                  element={<People setNavbarName={setBrandText} />}
                />

              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
