import Button from "components/button/button";
import InputField from "components/fields/InputField";
import Cookies from "js-cookie";
import { ChangeEvent, ChangeEventHandler, FormEvent, useState } from "react";
import apiCall from "utils/apicall";

export default function SignIn() {

  let [stoken, setStoken] = useState("")
  let [tokenError, setToeknError] = useState("")
  let [loginProcess, setLoginProcess] = useState(false)


  const loginTry = () => {

    setToeknError("")

    if (stoken == "") {
      setToeknError("Please Enter Token...")
      return
    }

    setLoginProcess(true)

    apiCall({
      method: "GET",
      url: "/auth/check",
      headers: {
        Authorization: stoken
      }
    }).then((res: any) => {

      if (res.data.status) {
        Cookies.set("ADMIN_TOKEN", stoken)
        window.location.href = "/a"
      } else {
        setToeknError("Invalid Token...")
        Cookies.remove("ADMIN_TOKEN")
      }

      setLoginProcess(false)
      
    }).catch((res) => {

      alert("Something Wrong...")
      
    })

  }

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">

      <div className="m-auto  mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-5 ml-1 text-base text-gray-600">
          Enter your Secret Token to sign in!
        </p>

        <InputField
          disabled={loginProcess}
          variant="auth"
          extra="mb-3"
          label="Secret Token*"
          placeholder="Secret Token"
          id="secret_token"
          type="password"
          value={stoken}
          onChange={(e: FormEvent<HTMLInputElement>) => setStoken(e.currentTarget.value)}
          error={tokenError}
        />

        <div className="mb-1 flex items-center justify-between px-2">

        </div>

        <Button
          process={loginProcess}
          extra="linear mt-2 w-full"
          name="Sigh In"
          onClick={() => loginTry()}
        />

      </div>
    </div>
  );
}
