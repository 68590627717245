import { useDisclosure } from "@chakra-ui/hooks"
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal"
import Button from "components/button/button"
import Card from "components/card"
import InputField from "components/fields/InputField"
import Spinner from "components/spinner/spinner"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import apiCall from "utils/apicall"

function Order({
  setNavbarName
}: {
  setNavbarName: any
}) {

  setNavbarName("Order")

  let { id: orderId } = useParams()

  const [loadOrders, setLoadOrders] = useState(true)
  const [orderInfo, setOrderInfo] = useState({
    info: {
      _id: "",
      status: "",
      payment_type: "",
      subtotal: 0,
      cart: {
        product_info: []
      },
      order_status: {
        order_id: ""
      },
      address: {
        email: "",
        name: "",
        phone: "",
        address1: "",
        address2: "",
        state: "",
        city: "",
        pincode: ""
      },
      invoice: "",
      track_url: "",
    },
    invoice: ""
  })

  const fetchAndLoadData = () => {

    setLoadOrders(true)

    apiCall({
      method: "POST",
      url: `/orders/info/${orderId}`,
      data: {},
    }).then((res: any) => {
      setOrderInfo(res.data)
      setLoadOrders(false)
    }).catch((_err) => {
      alert("Data Fetch Error..")
    })

  }

  useEffect(() => {
    fetchAndLoadData()
  }, [])

  const trackUrlModal = useDisclosure();

  const checkModal = useDisclosure();
  const checkOrder = () => {
    checkModal.onClose()
    setLoadOrders(true)

    apiCall({
      method: "POST",
      url: `/orders/change/status/${orderInfo.info._id}/CHECKED`,
      data: {},
      headers: {}
    }).then(() => {
      fetchAndLoadData()
    }).catch(() => {
      alert("Error... !")
    })
  }

  const cancleModal = useDisclosure();
  const cancleOrder = () => {
    cancleModal.onClose()
    setLoadOrders(true)

    apiCall({
      method: "POST",
      url: `/orders/change/status/${orderInfo.info._id}/CANCELLED`,
      data: {},
      headers: {}
    }).then(() => {
      fetchAndLoadData()
    }).catch(() => {
      alert("Error... !")
    })
  }

  return (

    loadOrders ?
      <div className="h-[500px] w-full flex">
        <Spinner />
      </div>
      :
      <div className="mt-6">

        <div className="flex gap-2">
          <h1 className="bg-purple-300 py-1 px-2 rounded-full text-white">#{orderInfo.info._id}</h1>
        </div>

        <div className="mt-3 flex">
          <h1 className="text-2xl font-bold px-6 py-2 bg-brandLinear text-white rounded-full">{orderInfo.info.status}</h1>
        </div>

        <div className="mt-4 flex gap-2">
          <h1 className="text-3xl font-bold">₹ {orderInfo.info.subtotal}</h1>
          {orderInfo.info.payment_type == "COD" ?
            <h2 className=" font-bold my-auto px-3 py-1 bg-orange-200 rounded-full text-white">COD</h2>
            :
            <h2 className=" font-bold my-auto px-3 py-1 bg-blue-400 rounded-full text-white">Online</h2>
          }
        </div>

        <div className="mt-3">
          <h1 className="text-3xl capitalize">{orderInfo.info.address.name}</h1>
          <h2>Phone No: {orderInfo.info.address.phone}</h2>
          <h2>Email: {orderInfo.info.address.email}</h2>
          <div className="my-2">
            <h1>Address:</h1>
            <h2 className="text-xl">{orderInfo.info.address.address1}</h2>
            {orderInfo.info.address.address2 && <h2 className="text-xl">{orderInfo.info.address.address2}</h2>}
            <h2 className="text-xl">{orderInfo.info.address.city}, {orderInfo.info.address.state} - {orderInfo.info.address.pincode}</h2>
          </div>
        </div>

        <Card extra={"w-full pb-4 p-4 h-full mt-3"}>
          <header className="relative flex items-center justify-between">
            <div className="capitalize text-xl font-bold text-navy-700 dark:text-white">
              Products
            </div>
          </header>

          {

            loadOrders ?
              <div className="h-[500px] w-full flex">
                <Spinner />
              </div>
              :
              <div className="mt-0 overflow-x-scroll xl:overflow-x-hidden">
                <table className="w-full">
                  <thead>
                    <tr className="!border-px !border-gray-400">
                      <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">No</th>
                      <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Name</th>
                      <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Que</th>
                      <th className="border-b-[1px] border-gray-200 pt-4 pb-2 px-4 text-start">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody style={{ cursor: "pointer" }}>
                    {orderInfo.info.cart.product_info.map((e, ei) => {
                      return (
                        <>
                          <tr key={ei}>
                            <td className="min-w-[150px] border-white/0 py-3  px-4">{ei + 1}</td>
                            <td className="min-w-[150px] border-white/0 py-3  px-4">{e.name}</td>
                            <td className="min-w-[150px] border-white/0 py-3  px-4">{e.que}</td>
                            <td className="min-w-[150px] border-white/0 py-3  px-4">₹ {e.subtotal}</td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
          }
        </Card>

        <div className="w-full mt-4 h-full mt-3 flex gap-4">
          {
            (orderInfo.info.status == "NEW" || orderInfo.info.status == "CANCEL REQUEST") &&

            <Button color="green" onClick={() => {
              checkModal.onOpen()
            }} name="Mark As Check" />

          }

          <Button onClick={() => {
            if (orderInfo.info.invoice == "" || !orderInfo.info.invoice) {
              window.open(orderInfo.invoice)
            } else {
              window.open("https://api.skinpassword.com/api/v1/invoice/" + orderInfo.info.invoice)
            }
          }} name="Download Invoice" />

          {
            orderInfo.info.status != "CANCELLED" &&
            <Button color="red" onClick={() => {
              cancleModal.onOpen()
            }} name="Cancel Order" />
          }
        </div>

        <div className="my-6">
          <h2 className="text-2xl">Track Url</h2>
          <div className="flex gap-2 my-2">
            <InputField
              className="!bg-white w-[400px] !m-0"
              disabled={false}
              variant=""
              placeholder="Track URL"
              value={orderInfo.info.track_url}
              onChange={(e: any) => {
                setOrderInfo((prev: any) => ({ ...prev, info: { ...prev.info, track_url: e.target.value } }))
              }}
            />
            <Button color='primary' onClick={() => {
              apiCall({
                method: "POST",
                url: `/orders/info/track/${orderInfo.info._id}`,
                data: {
                  url: orderInfo.info.track_url
                },
                headers: {}
              }).then(() => {
                trackUrlModal.onOpen()
              }).catch(() => {
                alert("Error... !")
              })
            }} name="Save" />
          </div>
        </div>

        <Modal isOpen={trackUrlModal.isOpen} onClose={trackUrlModal.onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
            <ModalBody>
              <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
                <h1 className="mb-[20px] text-2xl font-bold">Track URL Updated!</h1>
                <div className="flex gap-2">
                  <Button
                    name="Done"
                    onClick={trackUrlModal.onClose}
                  />
                </div>
              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={checkModal.isOpen} onClose={checkModal.onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
            <ModalBody>
              <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
                <h1 className="mb-[20px] text-2xl font-bold">Mark As Check</h1>
                <p className="mb-[20px]">
                  Confirmation For Check Order
                </p>
                <div className="flex gap-2">
                  <Button
                    name="Check"
                    color="green"
                    onClick={checkOrder}
                  />
                  <Button
                    name="Close"
                    onClick={checkModal.onClose}
                  />
                </div>
              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={cancleModal.isOpen} onClose={cancleModal.onClose}>
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
            <ModalBody>
              <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
                <h1 className="mb-[20px] text-2xl font-bold">Cancel Order</h1>
                <p className="mb-[20px]">
                  Confirmation For Cancel Order
                </p>
                <div className="flex gap-2">
                  <Button
                    name="Cancel Order"
                    color="red"
                    onClick={cancleOrder}
                  />
                  <Button
                    name="Close"
                    onClick={cancleModal.onClose}
                  />
                </div>
              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>

      </div>
  )
}

export default Order