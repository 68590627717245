import axios from "axios"
import Cookies from "js-cookie";

const axiosIntance = axios.create({
    baseURL: "https://api.skinpassword.com/api/admin/v1",
});

type apiCallProp = {
    method: string,
    url: string,
    data?: any,
    headers?: object
}

function apiCall(p: apiCallProp) {
    return new Promise((r, e) => {
        axiosIntance.request({
            method: p.method,
            url: p.url,
            data: p.data,
            headers: {
                Authorization: Cookies.get("ADMIN_TOKEN"),
                ...p.headers
            },
        }).then((res) => {

            if (res.data.notAuthError) {
                window.location.href = "/auth"
                return
            }

            r(res)

        }).catch((_err) => {

            if (_err.response.status == 401) {
                window.location.href = "/auth"
                return
            }

            e(_err)
        })
    })
}

export default apiCall
