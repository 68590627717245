import React, { useEffect } from "react"
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

function Protect({
    children
}: {
    children: React.ReactNode
}) {

    let token = Cookies.get("ADMIN_TOKEN")

    if (token === "" || token === undefined) {
        return (
            <>
                <Navigate to="/auth" />
            </>
        )
    }

    return (
        <>
            {children}
        </>
    )

}

export default Protect
