import Spinner from "components/spinner/spinner"
import Widget from "components/widget/Widget"
import { useEffect, useState } from "react"
import apiCall from "utils/apicall"
import PeopleTable from "../default/PeopleTable"

function People({
    setNavbarName
}: {
    setNavbarName: any
}) {

    setNavbarName("People")

    let [loading, setLoading] = useState(true)
    let [peopleOverView, setPeopleOverView] = useState({
        people_count: 0,
    })


    const fetchAndLoadData = () => {

        setLoading(true)

        apiCall({
            method: "POST",
            url: `/account/overview`,
            data: {}
        }).then((res: any) => {
            setPeopleOverView(res.data)
            setLoading(false)
        }).catch(() => {
            alert("error... data fetch")
        })

    }

    useEffect(() => {
        fetchAndLoadData()
    }, [])

    return (

        loading ?
            <div className="h-[300px] w-full flex">
                <Spinner />
            </div>
            :
            <div>

                <div className="mt-3 grid gap-5 grid-cols-4">
                    <Widget
                        title={"Total People"}
                        subtitle={peopleOverView.people_count.toString()}
                    />
                </div>

                <div className="mt-5 grid ">
                    <PeopleTable />
                </div>

            </div>
    );
}

export default People