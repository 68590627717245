import Footer from "components/footer/FooterAuthDefault";
import authImg from "assets/img/auth/auth.png";
import { Link, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "views/auth/SignIn";

export default function Auth() {

  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">

        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:pl-0 xl:max-w-full">

                <Routes>

                  <Route
                    path="/"
                    element={<SignIn />}
                  />

                  <Route path="*" element={<Navigate to="/a" replace />} />

                </Routes>
               
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
