import Card from "components/card";

const Widget = (props: {
  icon?: JSX.Element;
  title: string;
  subtitle: string;
}) => {
  const { icon, title, subtitle } = props;
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] min-h-[85px] py-4">
      {
        icon &&
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span className="flex items-center text-brand-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>
      }

      <div className="h-50 mx-6 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
        <h4 className="text-4xl font-bold text-navy-700 dark:text-white mt-2">
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
