import { useState } from "react";
import Dropdown from "components/dropdown";
import { AiOutlineUser } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { AiOutlineShop } from "react-icons/ai";
import { TiLightbulb } from "react-icons/ti";
import DropdownAlt from "components/dropdown/index_alt";

type CardMenuLinkType = {
  name: String,
  icon?: React.ReactNode,
  onClick: Function
}

function CardMenu(props: { transparent?: boolean, links?: CardMenuLinkType[] }) {
  const { transparent, links } = props;
  const [open, setOpen] = useState(false);
  return (
    <DropdownAlt
      open={open}
      setOpen={setOpen}
      button={
        <button
          // onClick={() => setOpen(!open)}
          className={`flex items-center text-xl hover:cursor-pointer ${transparent
            ? "bg-none text-white hover:bg-none active:bg-none"
            : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <BsThreeDots className="h-6 w-6" />
        </button>
      }
      animation={"origin-top-right transition-all duration-300 ease-in-out"}
      classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
      children={
        <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">

          {
            links &&
            links.map((e: CardMenuLinkType, e_i: number) => {
              return (
                <p key={e_i} onClick={() => { e.onClick(); setOpen(false); }} className="hover:text-gray-800 mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600">
                  {e.icon && (
                    <span>
                      {e.icon}
                    </span>
                  )}
                  {e.name}
                </p>
              )
            })
          }

        </div>
      }
    />
  );
}

export default CardMenu;
