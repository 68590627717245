import { color } from "@chakra-ui/system"
import ButtonSpinner from "components/button_spinner/spinner"

function Button(prop: {
    extra?: string,
    name: string,
    onClick?: any,
    disabled?: boolean,
    process?: boolean,
    color?: 'red' | 'primary' | 'green',
}) {

    let { extra, name, onClick, disabled, process, color } = prop

    let colorStyle = {
        "primary": "rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200",
        "red": "rounded-xl bg-red-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200",
        "green": "rounded-xl bg-green-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
    }

    return (
        <button disabled={disabled} onClick={onClick ? onClick : () => { }}
            className={`
            flex
            justify-center
            ${extra} 
            ${disabled || process ? "opacity-50 pointer-events-none" : ""}
            
            ${color ? colorStyle[color] : colorStyle['primary']}
            
            `}>
            {process && <ButtonSpinner />}
            <div>
                {name}
            </div>
        </button>
    )
}

export default Button